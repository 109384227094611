import { useHistory } from "react-router";
import {
  ChatIcon,
  ClipboardListIcon,
  UserIcon,
  HomeIcon,
  GiftIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";

const nav = [
  { name: "Home", to: "/home", icon: HomeIcon, authProtected: false },
  {
    name: "User",
    to: "/user-list",
    icon: UserIcon,
    authProtected: false,
  },
  {
    name: "Lottery",
    to: "/lottery",
    icon: ClipboardListIcon,
    authProtected: false,
  },
  {
    name: "Scrach Card",
    to: "/scratch-card",
    icon: GiftIcon,
    authProtected: false,
  },
  {
    name: "Game Guides",
    to: "/game-guides",
    icon: DocumentTextIcon,
    authProtected: false,
  },
  {
    name: "Complaint Queries",
    to: "/query-list",
    icon: ChatIcon,
    authProtected: false,
  },
];

const Navigation = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return nav.map((m) => ({ ...m, current: m.to === pathname }));
};

export default Navigation;
