import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const isObject = (a) => {
  return !!a && a.constructor === Object;
};

export const numberFormat = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export function numberToWords(num) {
  const words = [
    { value: 1e9, str: "Billion" },
    { value: 1e6, str: "Million" },
    { value: 1e3, str: "Thousand" },
    { value: 1e2, str: "Hundred" },
  ];

  for (let i = 0; i < words.length; i++) {
    if (num >= words[i].value) {
      return (num / words[i].value).toFixed(1) + " " + words[i].str;
    }
  }
  return num.toString();
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const copyTextToClipboard = (elementId) => {
  var copyText = document.getElementById(elementId);
  navigator.clipboard
    .writeText(copyText.value)
    .then(() => {
      enqueueSnackbar("Wallet Address Copied!", {
        variant: "success",
      });
    })
    .catch((err) => {
      enqueueSnackbar("Failed to copy: " + err, {
        variant: "error",
      });
    });
};

export function validatePhoneNumber(value) {
  // Validate phone number format: 123-456-7890
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  return phoneRegex.test(value);
}

export function convertToPhoneFormat(value) {
  let formattedValue = value;

  if (value.length > 3 && value.length <= 6) {
    formattedValue = value.slice(0, 3) + "-" + value.slice(3);
  } else if (value.length > 6) {
    formattedValue =
      value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);
  }
  return formattedValue;
}

export const calculateTotalAmount = (lotteryData) => {
  const tierKeys = [
    "jackpot",
    "fiveCorrect",
    "fiveCorrect1",
    "fourCorrect",
    "fourCorrect1",
    "fourCorrect2",
    "threeCorrect",
    "threeCorrect1",
    "threeCorrect2",
    "twoCorrect",
    "twoCorrect1",
    "twoCorrect2",
    "oneCorrect",
    "oneCorrect1",
    "oneCorrect2",
  ];

  let totalAmount = parseFloat(lotteryData.jackpot);

  tierKeys.forEach((key) => {
    totalAmount += parseFloat(lotteryData[key] || 0);
  });

  return numberToWords(totalAmount);
};
