import React from "react";
import ReactDOM from "react-dom/client";

// imports for context provider
import { ThemeProvider } from "./themeContext";
import { SnackbarProvider } from "notistack";

// imports for component and styles
import App from "./App";
import "./index.css";
import { AuthProvider } from "./contexts/JWTAuthContext";

const root = document.getElementById("root");

const renderApp = () => {
  ReactDOM.createRoot(root).render(
    // <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
    // </React.StrictMode>
  );
};

// Initial render
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more
