export const BASEURL =
  "https://2wuq1i74kl.execute-api.eu-north-1.amazonaws.com/master";
// export const BASEURL =
//   "https://skzjifm0ll.execute-api.eu-north-1.amazonaws.com/dev";
// export const BASEURL = "http://localhost:3000/master";

export const STATUSOPTIONS = [
  { label: "Active", value: true },
  { label: "In Active", value: false },
];

export const ROLEOPTIONS = [
  { label: "Admin", value: "Admin" },
  { label: "User", value: "User" },
];

export const CHARTFILTER = [
  { id: 1, label: "Today", value: "Today" },
  { id: 2, label: "Last Week", value: "Week" },
  { id: 3, label: "Month", value: "Month" },
  { id: 4, label: "Year", value: "Year" },
  { id: 5, label: "All Time", value: "All" },
];
