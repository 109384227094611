import React from "react";
import GuestGuard from "../components/GuestGuard";
// import AuthGuard from '../components/AuthGuard';

const Home = React.lazy(() => import("../views/Home/Home"));
const Login = React.lazy(() => import("../views/Auth/Login"));
const LotteryList = React.lazy(() => import("../views/Lottery/LotteryList"));
const AddLottery = React.lazy(() => import("../views/Lottery/AddLottery"));
const UpdateLottery = React.lazy(() =>
  import("../views/Lottery/PublishLottery")
);
const QueryList = React.lazy(() => import("../views/Queries/QueryList"));
const QueryReply = React.lazy(() => import("../views/Queries/ReplyQuery"));
const UserList = React.lazy(() => import("../views/Users/UserList"));
const EditUser = React.lazy(() => import("../views/Users/UpdateUser"));
const AddUser = React.lazy(() => import("../views/Users/AddUser"));
const ErrorPage = React.lazy(() => import("../views/ErrorPage"));
const ScratchCardConfig = React.lazy(() =>
  import("../views/ScratchCardConfig")
);
const GameGuides = React.lazy(() => import("../views/GameGuides"));
const ForgotPassword = React.lazy(() => import("../views/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../views/Auth/ResetPassword"));

const routes = [
  {
    path: "/home",
    pageName: "Home",
    exact: true,
    withSidebar: true,
    component: Home,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/lottery",
    pageName: "Lottery",
    exact: true,
    withSidebar: true,
    component: LotteryList,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/create-lottery",
    pageName: "Create Lottery",
    exact: true,
    withSidebar: true,
    component: AddLottery,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/publish-lottery",
    pageName: "Publish Lottery",
    exact: true,
    withSidebar: true,
    component: UpdateLottery,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/query-list",
    pageName: "Query List",
    exact: true,
    withSidebar: true,
    component: QueryList,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/query-reply",
    pageName: "Query Reply",
    exact: true,
    withSidebar: true,
    component: QueryReply,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/user-list",
    pageName: "User List",
    exact: true,
    withSidebar: true,
    component: UserList,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-user",
    pageName: "Add User",
    exact: true,
    withSidebar: true,
    component: AddUser,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/edit-user",
    pageName: "Edit User",
    exact: true,
    withSidebar: true,
    component: EditUser,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/scratch-card",
    pageName: "Scratch Card",
    exact: true,
    withSidebar: true,
    component: ScratchCardConfig,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/game-guides",
    pageName: "Game Guides",
    exact: true,
    withSidebar: true,
    component: GameGuides,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/",
    pageName: "Login",
    exact: true,
    guard: GuestGuard,
    component: Login,
    title: "",
    subtitle: "",
  },
  {
    path: "/forgot-password",
    pageName: "Forgot Password",
    exact: true,
    guard: GuestGuard,
    component: ForgotPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "/reset-password",
    pageName: "Reset Password",
    exact: true,
    guard: GuestGuard,
    component: ResetPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "",
    pageName: "Error",
    withSidebar: false,
    component: ErrorPage,
    title: "404",
    bgImg: "md:bg-commonSectionBg",
    mobileBgImg: "bg-Mobile404Bg",
    bgImgClass: "bg-100% bg-no-repeat bg-bottom",
  },
];

export default routes;
