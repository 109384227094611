import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import { classNames } from "../../functions/utils";
import getNav from "../../components/common/sidebarNav";
import Logo from "../../assets/img/Mega Pot White.svg";

import "react-perfect-scrollbar/dist/css/styles.css";

const Sidebar = ({ sidebarOpen, setSideBarOpen }) => {
  const dialogRef = useRef(null);
  const navigation = getNav();

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        unmount
        as="div"
        className="fixed inset-0 z-40 flex lg:hidden"
        onClose={() => setSideBarOpen(false)}
        initialFocus={dialogRef}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative max-w-xs w-full bg-green dark:bg-sidebar-dark-bg pt-5 pb-4 flex-1 flex flex-col px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  ref={dialogRef}
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0"
                  onClick={() => setSideBarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center justify-between">
              <Link to="/">
                <img className="h-16 w-auto" src={Logo} alt="Easywire logo" />
              </Link>
              {/* <ThemeToggleSwitch /> */}
            </div>
            <div className="mt-6 flex-1 h-0 overflow-y-auto">
              <nav className="space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                      item.current
                        ? "bg-light-green text-dark-green"
                        : "text-white hover:text-white hover:bg-cyan-600",
                      "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? " text-dark-green" : "text-white",
                        "mr-4 flex-shrink-0 h-6 w-6 text-white"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
