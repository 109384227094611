import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
// import { ToastContainer } from "react-toastify";

import routes from "./routes";
// import useEagerDisconnect from './hooks/useEagerDisconnect';
import PublicRoute from "./routes/PublicRoute";

import "@fortawesome/fontawesome-free/css/all.min.css";
// import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App = (props) => {
  // useEagerDisconnect();
  return (
    <BrowserRouter>
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Switch>
        {routes.map((m, i) => (
          <PublicRoute key={i} {...m} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
